import {
  Vue, Component, Prop, Emit, PropSync,
} from 'vue-property-decorator';
import { Condition as ConditionType, Field } from '@/store/modules/rule/rule-types';
import RuleStore from '@/store/modules/rule/rule-module';
import FieldComponent from '@/components/Field/Field.vue';

@Component({
  components: {
    FieldComponent,
    SubCondition: () => import('./Condition.vue'),
  },
})
export default class Condition extends Vue {
  @Prop()
  condition!: ConditionType;

  @Prop()
  title!: string;

  @Prop()
  isSubCondition!: boolean;

  @PropSync('defaultValue')
  defaultType!: any;

  mounted() {
    this.initializeFields();
  }

  get conditionTypes() {
    return RuleStore.conditionTypes;
  }

  get datasource() {
    return RuleStore.datasource;
  }

  get fieldTypes() {
    return RuleStore.ruleFieldTypes;
  }

  get fieldFunctions() {
    return RuleStore.fieldFunctions;
  }

  get constants() {
    return RuleStore.fieldConstants;
  }

  get operators() {
    return RuleStore.operators;
  }

  get showFieldThree() {
    return this.operators.some((op) => op.value === this.condition.operator && op.type === 'TERNARY');
  }

  get showFieldTwo() {
    return this.operators.some((op) => op.value === this.condition.operator && (op.type === 'TERNARY' || op.type === 'BINARY'));
  }

  initializeFields() {
    if (this.condition.fields?.length > 0) {
      this.orderConditions();
      Vue.set<Field[]>(this.condition, 'fields', this.condition.fields);
    } else {
      Vue.set<Field[]>(this.condition, 'fields', [new Field(1), new Field(2)]);
    }
  }

  get datasourceColumns() {
    const [selectedDatasource] = this.datasource.filter((data) => data.value === this.condition.dataSource);
    return selectedDatasource?.columns || [];
  }

  addSubCondition() {
    const subCondition = new ConditionType();
    subCondition.id = this.condition.children.length + 1;
    subCondition.order = this.condition.children.length + 1;
    subCondition.isSubCondition = true;
    Vue.set<ConditionType[]>(this.condition, 'children', [...this.condition.children, subCondition]);
  }

  @Emit('on-remove')
  removeCondition() {
    return this.condition.id;
  }

  removeSubCondition(subConditionId: number) {
    const newSubConditionList = this.condition.children.filter((subCondition) => subCondition.id !== subConditionId);
    Vue.set(this.condition, 'children', newSubConditionList);
  }

  operatorChange(operator: string) {
    if (this.showFieldThree) {
      const { fields } = this.condition;
      fields.push(new Field(3));
    } else {
      this.condition.fields = this.condition.fields.filter((field) => field.order !== 3);
    }
  }

  orderConditions() {
    this.condition.fields.sort((a, b) => a.order - b.order);
  }
}
