import SelectFieldInterface, { FieldFunction } from '@/shared/models/select-field-interface';
import DateUtils from '@/shared/utils/date-utils';
import SelectFieldType, { Condition, Field as FieldType } from '@/store/modules/rule/rule-types';
import { format, isMatch } from 'date-fns';
import {
  Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { mask } from 'vue-the-mask';
import { VueMaskFilter } from 'v-mask';

@Component({
  filters: {
    defaultValueFilter(value: any) {
      if (isMatch(value, 'yyyy-MM-dd')) {
        return value.split('-').reverse().join('/');
      }

      return value;
    },
  },
  directives: { mask },
})
export default class Field extends Vue {
  @Prop()
  title!: string;

  @Prop()
  fieldTypes!: SelectFieldType[];

  @Prop()
  fieldFunctions!: FieldFunction[];

  @Prop()
  condition!: Condition;

  @Prop()
  datasourceColumns!: SelectFieldInterface<string>[];

  @Prop()
  constants!: SelectFieldInterface<string>[];

  @PropSync('field')
  fieldProp!: FieldType;

  @Watch('fieldProp')
  orderFieldProp(val: any) {
    if (val) {
      this.orderParameters();
    }
  }

  @PropSync('defaultValue')
  defaultType!: any;

  menu2 = false;

  menuDataParameter = false;

  modalModels: { show?: boolean } = {};

  dateComboErrors: string[] = [];

  valueInValue = '';

  functionsFiltered: any = [];

  mounted() {
    if (this.condition.operator === 'in' || this.condition.operator === 'not in') {
      this.fieldProp.valuein = this.fieldProp.value.split('|').map((value) => ({ value }));
    }
  }

  @Watch('fieldFunctions')
  loadFilteredFunctions() {
    this.filterFunctions();
  }

  @Watch('valueInValue')
  valueInValueChange(val: any, prev: any) {
    this.valueInValue = VueMaskFilter(val, '##/##/####');
  }

  @Watch('condition.operator')
  operatorChange(val: any, prev: any) {
    if (prev && this.fieldProp.order > 1) {
      this.fieldProp.value = '';
      this.fieldProp.valuein = [];
    }
  }

  @Watch('fieldProp.valuein')
  valueInChange(val: any[], prev: any[]) {
    if (val.length === (prev && prev.length)) {
      return
    }
    const isDateType = this.fieldProp.type === 'DATA';
    const newArrayVal: any = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const value of val) {
      if (isDateType) {
        if (typeof value === 'string' && isMatch(value, 'dd/MM/yyyy')) {
          this.dateComboErrors = [];
          newArrayVal.push({ value });
        } else if (typeof value !== 'string') {
          newArrayVal.push(value);
        } else {
          this.dateComboErrors.push('Formato de data ou data inválida');
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (typeof value === 'string') {
          newArrayVal.push({ value });
        } else {
          newArrayVal.push(value);
        }
      }
    }
    this.fieldProp.valuein = newArrayVal;
  }

  // eslint-disable-next-line consistent-return
  getcomputedDateFormatted() {
    if (this.fieldProp?.value) {
      return format(DateUtils.normalizedDate(this.fieldProp?.value!), 'dd/MM/yyyy');
    }
  }

  // eslint-disable-next-line consistent-return
  formatDate(date: string) {
    if (date) {
      try {
        return format(DateUtils.normalizedDate(date), 'dd/MM/yyyy');
      } catch (e) {
        return date;
      }
    }
  }

  isDateValid(date: string) {
    return isMatch(date, 'dd/MM/yyyy');
  }

  get computedFieldTypes() {
    if (this.fieldProp.order === 1) {
      return this.fieldTypes.filter((type) => type.showOnRecordField);
    }

    if (this.condition.operator === 'in' || this.condition.operator === 'not in') {
      return this.fieldTypes.filter((type) => type.showOnDefaultValue);
    }

    return this.fieldTypes;
  }

  get functionParamsTypes() {
    return this.fieldTypes.filter((type) => type.showOnFunctionParam);
  }

  selectFieldFunctionsValue(value: string) {
    const [selectedFunc] = this.fieldFunctions.filter((func) => func.id === value);
    const parameters = selectedFunc.parameters.map((param) => {
      Vue.set(this.modalModels, param.name, false);
      return {
        name: param.name,
        order: param.order,
        type: '',
        value: '',
      };
    });
    Vue.set(this.fieldProp, 'parameters', parameters);
  }

  selectFunctionType(fieldProp: FieldType, isParameter: boolean) {
    if (fieldProp.type === 'VALOR_PADRAO') {
      // eslint-disable-next-line no-param-reassign
      fieldProp.value = this.defaultType;
    } else if (!isParameter) {
      // eslint-disable-next-line no-param-reassign
      fieldProp.value = '';
      // eslint-disable-next-line no-param-reassign
      fieldProp.valuein = [];
      // eslint-disable-next-line no-param-reassign
      fieldProp.parameters = [];
    } else {
      // eslint-disable-next-line no-param-reassign
      fieldProp.valuein = [];
      // eslint-disable-next-line no-param-reassign
      fieldProp.value = '';
    }
  }

  defaultTypeValueChange(field: FieldType) {
    // eslint-disable-next-line no-param-reassign
    field.value = this.defaultType;
    return field.value;
  }

  filterFunctions() {
    this.functionsFiltered = this.fieldFunctions.filter((element) => element.id === 'data_atual');
  }

  orderParameters() {
    if (this.fieldProp && (this.fieldProp.parameters && this.fieldProp.parameters.length > 0)) {
      this.fieldProp.parameters.sort((a, b) => a.order - b.order);
    }
  }
}
