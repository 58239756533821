import { isMatch } from 'date-fns';

export default class DateUtils {

  static normalizedDate(dateString: string): Date {
    if (isMatch(dateString, 'dd-MM-yyyy')) {
      const [year, month, day] = dateString.split('-').reverse();
      return new Date(parseInt(year, 10), (parseInt(month, 10) - 1), parseInt(day, 10));
    }

    if (isMatch(dateString, 'dd/MM/yyyy')) {
      const [year, month, day] = dateString.split('/').reverse();
      return new Date(parseInt(year, 10), (parseInt(month, 10) - 1), parseInt(day, 10));
    }

    if (isMatch(dateString, 'yyyy-MM-dd')) {
      const [year, month, day] = dateString.split('-');
      return new Date(parseInt(year, 10), (parseInt(month, 10) - 1), parseInt(day, 10));
    }

    if (dateString.includes('T')) {
      const [dateWithoutTimeZone] = dateString.split('T');
      const [year, month, day] = dateWithoutTimeZone.split('-');
      return new Date(parseInt(year, 10), (parseInt(month, 10) - 1), parseInt(day, 10));
    }

    return new Date(dateString);
  }

}
