import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import RuleStore from '@/store/modules/rule/rule-module';
import Footer from '@/components/Footer/Footer.vue';
import Condition from '@/components/Condition/Condition.vue';
import router from '@/router';
import { Condition as ConditionType, Rule } from '@/store/modules/rule/rule-types';
import { format } from 'date-fns';
import DateUtils from '@/shared/utils/date-utils';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';
import SimpleVueValidation from 'simple-vue-validator';
import MovementRecordTypeService from '@/services-http/sdi/MovementRecordTypeService';
import NewRules from '@/shared/validators/formRules';

const { Validator } = SimpleVueValidation;

@Component({
  components: { Footer, Condition, PrismEditor },
  validators: {
    'rule.name': (value: string) => Validator.value(value).required('Nome da Regra é um campo obrigatório'),
    'rule.message': (value: string) => Validator.value(value).required('Mensagem é um campo obrigatório'),
  },
})
export default class RuleNew extends Vue {
  @Prop() readonly isEdit!: boolean;

  @Prop() readonly idRule!: number;

  @Prop() readonly copyId!: number;

  kinshipService = new MovementRecordTypeService();

  rule = new Rule();

  newRule = new NewRules();

  search = null;

  menu2 = false;

  date = new Date().toISOString().substr(0, 10);

  movementTypeToggle: number[] = [];

  languageScript = ['Javascript', 'Python'];

  languageScripts = [
    {
      label: 'Javascript',
      value: '1',
    },
    {
      label: 'Python',
      value: '2',
    },
  ];

  movementRecordColumns: any = [
    {
      label: '',
      value: '',
    },
  ];

  movementRecordTypes = [
    {
      id: 0,
      name: '',
      description: '',
      code: '',
    },
  ];

  movementRecordTypesAux = [
    {
      id: 0,
      name: '',
      description: '',
      code: '',
    },
  ];

  movementRecordTypesSelecteds = [
    {
      id: 0,
      name: '',
      description: '',
      code: '',
    },
  ];

  addButtonDisabled = true;

  removeButtonDisabled = true;

  movementRecordTypeIndexToAdd = [];

  movementRecordTypeIndexToRemove = [];

  loadingMovementRecordColumns = true;

  @Watch('movementRecordTypeIndexToAdd', { immediate: true, deep: true })
  onSelectToAdd(array: any) {
    if (array.length) {
      this.addButtonDisabled = false;
    } else {
      this.addButtonDisabled = true;
    }
    this.removeButtonDisabled = true;
  }

  @Watch('movementRecordTypeIndexToRemove', { immediate: true, deep: true })
  onSelectToRemove(array: any) {
    if (array.length) {
      this.removeButtonDisabled = false;
    } else {
      this.removeButtonDisabled = true;
    }
    this.addButtonDisabled = true;
  }

  clearAdd() {
    this.movementRecordTypeIndexToAdd = [];
  }

  clearRemove() {
    this.movementRecordTypeIndexToRemove = [];
  }

  addSelecteds() {
    if (this.movementRecordTypeIndexToAdd.length) {
      this.movementRecordTypeIndexToAdd.forEach((index) => {
        const item = this.movementRecordTypes[index];
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.movementRecordTypesSelecteds.push(item);
      });

      this.movementRecordTypeIndexToAdd.sort().reverse();
      this.movementRecordTypeIndexToAdd.forEach((index) => {
        this.movementRecordTypes.splice(index, 1);
      });

      this.movementRecordTypes.sort((a, b) => (a.name > b.name ? 1 : -1));
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.movementRecordTypesSelecteds.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.movementRecordTypeIndexToAdd = [];
      this.verifyIfEnabled();
    }
  }

  removeSelecteds() {
    if (this.movementRecordTypeIndexToRemove.length) {
      this.movementRecordTypeIndexToRemove.forEach((index) => {
        const item = this.movementRecordTypesSelecteds[index];
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.movementRecordTypes.push(item);
      });

      this.movementRecordTypeIndexToRemove.sort().reverse();
      this.movementRecordTypeIndexToRemove.forEach((index) => {
        this.movementRecordTypesSelecteds.splice(index, 1);
      });

      this.movementRecordTypesSelecteds.sort((a, b) => (a.name > b.name ? 1 : -1));
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.movementRecordTypes.sort((a, b) => (a.name > b.name ? 1 : -1));
      this.movementRecordTypeIndexToRemove = [];
      this.verifyIfEnabled();
    }
  }

  verifyIfEnabled() {
    if (!this.movementRecordTypes.length) {
      this.addButtonDisabled = true;
    } else {
      this.addButtonDisabled = false;
    }

    if (!this.movementRecordTypesSelecteds.length) {
      this.removeButtonDisabled = true;
    } else {
      this.removeButtonDisabled = true;
    }
  }

  loadMovementRecordColumns() {
    this.movementRecordColumns = [];
    if (this.datasource && this.datasource.length) {
      this.movementRecordColumns = this.datasource[0].columns;
    }

    this.loadingMovementRecordColumns = false;
  }

  async loadMovementTypes() {
    this.movementRecordTypes = [];
    this.movementRecordTypesAux = [];
    this.movementRecordTypesSelecteds = [];
    await this.kinshipService.FindAll().then((response) => {
      if (response && response.data && response.data.length) {
        this.movementRecordTypesAux = response.data;
        this.movementRecordTypesAux.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
    });
  }

  loadMovementTypesFromRule() {
    if (this.rule.type === 'SCRIPT') {
      this.rule.type = 1;
    } else if(this.rule.type === 'GROOVY') {
      this.rule.type = 2;
    } else {
      this.rule.type = 0;
    }
    const allMovementsTypes = this.movementRecordTypesAux;
    const movementTypesFromRule = this.rule.movementTypes;

    this.movementRecordTypesSelecteds = this.rule.movementTypes;

    if (allMovementsTypes.length && movementTypesFromRule.length) {
      const elementsDifferents = allMovementsTypes.filter((item1) => !movementTypesFromRule.some((item2) => item1.id === item2.id));

      this.movementRecordTypesAux = elementsDifferents;
      this.movementRecordTypes = this.movementRecordTypesAux;
    } else {
      this.movementRecordTypes = this.movementRecordTypesAux;
    }
  }

  code = '\n\n\n\n\n\n\n';

  loading = false;

  async mounted() {
    this.$validate();
    await this.loadMovementTypes();

    if (this.isEdit || this.copyId) {
      await RuleStore.findRuleById(this.idRule || this.copyId);
      Vue.set(this, 'rule', RuleStore.rule);
      this.loadMovementTypesFromRule();
    } else {
      this.movementRecordTypes = this.movementRecordTypesAux;
      const initialCondition = new ConditionType();
      initialCondition.order = 1;
      Vue.set(this.rule, 'conditions', [initialCondition]);
    }

    await RuleStore.getConditionTypes();
    await RuleStore.getDatasources();
    await RuleStore.getFieldTypes();
    await RuleStore.getFieldFunctions();
    await RuleStore.getFieldConstants();
    await RuleStore.getOperators();
    await RuleStore.getDefaultValueDescriptions();
    await RuleStore.getRuleTags();

    this.prepareToggleMovementType();
    this.loadMovementRecordColumns();

    if (!this.rule.script) {
      this.rule.script = this.code;
    }
  }

  get ruleIndex() {
    return RuleStore.ruleIndex || false;
  }

  get ruleTags() {
    return RuleStore.ruleTags;
  }

  get ruleFieldTypes() {
    return RuleStore.fieldTypes.filter((field) => field.showOnDefaultValue);
  }

  get defaultValueDescriptions() {
    return RuleStore.defaultValueDescriptions;
  }

  getcomputedDateFormatted() {
    if (this.rule?.defaultValue) {
      return format(DateUtils.normalizedDate(this.rule?.defaultValue!), 'dd/MM/yyyy');
    }
    return null;
  }

  getError(fieldName: string) {
    return this.validation.isTouched(fieldName) ? this.validation.firstError(fieldName) : [];
  }

  get conditionTypes() {
    return RuleStore.conditionTypes;
  }

  get datasource() {
    return RuleStore.datasource;
  }

  back() {
    router.push('/rule');
  }

  cancel() {
    router.push('/home-all-menus');
  }

  async save() {
    if ((this.$refs.formNames as Vue & { validate: () => boolean }).validate()) {
      try {
        this.loading = true;
        this.sanitizeData();
        const movementTypes = this.movementRecordTypesSelecteds;
        this.rule.movementTypes = movementTypes;

        if (this.copyId) {
          const { id, idRule, ...rule } = this.rule;
          await RuleStore.saveRule(rule);
        } else {
          await RuleStore.saveRule(this.rule);
        }
      } catch (error) {
        Vue.toasted.global.customError('Ocorreu um erro ao processar sua requisição, '
          + 'tente novamente mais tarde.');
      } finally {
        this.loading = false;
      }
    }
  }

  sanitizeData() {
    if (this.rule.type === 1) {
      this.rule.conditions = [];
    } else if(this.rule.type === 2) {
      this.rule.conditions = [];
      this.rule.scriptLanguage = '';
    } else {
      this.rule.script = '';
      this.rule.scriptLanguage = '';
    }
  }

  addCondition() {
    const condition = new ConditionType();
    condition.id = this.rule.conditions.length + 1;
    condition.order = this.rule.conditions.length + 1;
    const conditions = this.rule.conditions
      || Vue.set<ConditionType[]>(this.rule, 'conditions', []);
    conditions.push(condition);
  }

  removeCondition(conditionId: number) {
    const newConditionList = this.rule.conditions
      .filter((condition) => condition.id !== conditionId);
    Vue.set(this.rule, 'conditions', newConditionList);
  }

  setMovementType(movementFieldName: 'implantation' | 'inclusion' | 'exclusion' | 'alteration') {
    this.rule[movementFieldName] = !this.rule[movementFieldName];
  }

  prepareToggleMovementType() {
    // eslint-disable-next-line no-unused-expressions
    this.rule.implantation ? this.movementTypeToggle.push(0) : null;
    // eslint-disable-next-line no-unused-expressions
    this.rule.inclusion ? this.movementTypeToggle.push(1) : null;
    // eslint-disable-next-line no-unused-expressions
    this.rule.exclusion ? this.movementTypeToggle.push(2) : null;
    // eslint-disable-next-line no-unused-expressions
    this.rule.alteration ? this.movementTypeToggle.push(3) : null;
  }

  setDefaultTypeDate(selectedDate: string) {
    this.rule.defaultValue = selectedDate;
  }

  clearDefaultTypeValue() {
    this.rule.defaultValue = '';
  }

  highlighter(code: string) {
    return highlight(code, languages.js);
  }
}
